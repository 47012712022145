/**
 * @constant
 * global flag for website
 */
export const IS_USE_PHOTO_WEDDING = false;

/**
 * @constants
 * for Brides information
 */
export const THE_BRIDE = 'Deni & Ayu';
export const GIRL_NAME = 'Ayu Tiya Fitasari, S.Pi';
export const GIRL_PARENT_NAME = `Putri dari Bapak Muhammad Yamin<br /> & Ibu Baiq Agustina Sutrawasi`;
export const BOY_NAME = 'Deni Ismanto, S.Pt';
export const BOY_PARENT_NAME = `Putra dari Bapak Ismail<br />& Ibu Mahyuni`;

/**
 * @constants - SEO Requirement
 */
export const SEO_IMAGE = 'https://github.com/invetto/assets/blob/main/2-f37d865bf19c620ec05d8b8f1faeb70c.jpeg';
export const SEO_URL = 'https://deni-ayu.invetto.id/';
export const SEO_TITLE = `The Wedding of ${THE_BRIDE} by Invetto.id`;
export const SEO_DESCRIPTION = `Dengan memohon Rahmat dan Ridho Illahi, teriring niat menjalankan Sunnah Rasulullah ﷺ untuk membentuk rumah tangga yang Sakinah, Mawaddah wa Rahmah, kami mohon do'a agar senantiasa diberikan kelancaran dan keberkahan. - ${THE_BRIDE}`;

/**
 * @constants - google maps
 * for google maps configuration
 */
export const GOOGLE_MAPS_LINK = `https://goo.gl/maps/J34aAcvBgUhNuNAW7`;
export const GOOGLE_MAPS_EMBED = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2330.072742070935!2d117.4069771827424!3d-8.503614558516238!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dcb9327653e7c1b%3A0xbb08a9f4008c58b9!2sUniversitas%20Samawa%20Sumbawa%20Besar!5e0!3m2!1sid!2sid!4v1688133906342!5m2!1sid!2sid`;
export const GOOGLE_MAPS_ADDRESS =
  'Gedung Aula Universitas Samawa, Jl. Bypass Sering, Kerato, Kec. Unter Iwes, Kabupaten Sumbawa, Nusa Tenggara Bar. 84316';

/**
 * @constants - epoch time
 * this configuration for wedding time
 * use this website for convert local time to epoch time
 * https://www.epochconverter.com/
 */
export const EPOCH_START_EVENT = 1688781600;
export const EPOCH_END_EVENT = 1688792400;

/**
 * @constants - wedding information
 */
export const WEDDING_AKAD = 'Selasa, 04 Juli 2023';
export const WEDDING_AKAD_TIME = '08.00 WITA - Selesai';
export const WEDDING_LOCATION_AKAD = GOOGLE_MAPS_ADDRESS;
export const WEDDING_RESEPSI = 'Sabtu, 08 Juli 2023';
export const WEDDING_RESEPSI_TIME = '09.00 - 12.00 WITA';
export const WEDDING_LOCATION = WEDDING_LOCATION_AKAD;
export const WEDDING_DATE = '18.08.2030';

/**
 * @constants
 * for footer sections
 */
export const SOUND_BY = `Christina Perri - A Thousand Years [Official Music Video]`;
export const SOUND_URL = 'https://youtu.be/rtOvBOTyX00';

/**
 * @constant
 * Live streaming & youtube Live
 */
export const YOUTUBE_LINK = '';
export const YOUTUBE_EMBED = '';

/**
 * @constant
 * for footer & copyright section
 */
export const URL_INVETTO = 'https://invetto.id';
export const URL_IG_INVETTO = 'https://www.instagram.com/invetto.id/';
export const URL_WA_INVETTO =
  'https://wa.me/+6282340780502?text=Hi%20Kak,%20aku%20mau%20pesan%20undangan%20online%20nih!';

/**
 * @constant
 * RestAPI
 */
export const API_HOSTNAME = 'https://api.invetto.id';
export const BRIDE_CODE = 'deni-ayu';

/**
 * @constant
 * Generate Link With spreadsheets
 */

export const URL_SPREADSHEETS =
  'https://docs.google.com/spreadsheets/d/1gxDfpAo-M2XN1cnNWuq6C4qWx2AwSvj5oK4rNJe63uI/edit?usp=sharing';
